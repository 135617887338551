/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type ReactNode } from 'react';

import { cssMap, jsx } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { useMenuSectionContext } from './menu-section-context';

const styles = cssMap({
	root: {
		color: token('color.text.subtlest'),
		font: token('font.heading.xxsmall'),
		paddingBlock: token('space.100'),
		paddingInlineStart: token('space.075'),
	},
});

/**
 * __Menu section heading__
 *
 * A menu section heading {description}.
 */
export const MenuSectionHeading = ({ children }: { children: ReactNode }): JSX.Element => {
	const id = useMenuSectionContext();

	return (
		<h2 css={styles.root} id={`${id}-heading`}>
			{children}
		</h2>
	);
};
