import React, { useCallback, type FC, useEffect, useContext } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { ButtonGroup } from '@atlaskit/button';
import { token } from '@atlaskit/tokens';

import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { h400Compiled } from '@confluence/typography-placeholder';
import {
	ExperienceStart,
	ExperienceSuccess,
	ExperienceTrackerContext,
	VIEW_GUEST_SPACES_MENU_REQUEST_TO_UPGRADE_EXPERIENCE,
	SUBMIT_GUEST_SPACES_MENU_REQUEST_TO_UPGRADE_EXPERIENCE,
} from '@confluence/experience-tracker';
import { isUnauthorizedError } from '@confluence/error-boundary';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';

import { GuestRequestUpgradeButton } from './GuestRequestUpgradeButton';
import { useSubmitGuestUpgradeRequest } from './useSubmitGuestUpgradeRequest';
import { GuestRequestToUpgradeLearnMoreDialogModal } from './GuestRequestToUpgradeLearnMoreModal';
import { useGuestRequestToUpgradeStatus } from './useGuestRequestToUpgradeStatus';
import { UpgradeStatus } from './types';

const TRACK_EVENT = 'sendTrackEvent';
export const ANALYTICS_SOURCE = 'guestSpacesMenu';

export const i18n = defineMessages({
	heading: {
		id: 'external-collab-ui.guest-request-to-upgrade-spaces-menu.heading',
		defaultMessage: 'Unlock more spaces',
		description: 'Heading inviting a guest to upgrade to a user',
	},
	body: {
		id: 'external-collab-ui.guest-request-to-upgrade-spaces-mneu.body',
		defaultMessage:
			'Request full access to this Confluence site to collaborate with your team across multiple spaces. {learnmorelink}',
		description:
			'Context which explains that upgrading unlocks new features. The placeholder {learnmorelink} will be replaced with a button that opens a modal with more details about the upgrade.',
	},
	learnMoreButton: {
		id: 'external-collab-ui.guest-request-to-upgrade.learn-more-button',
		defaultMessage: 'Learn more',
		description: 'Button text to learn more about upgrading',
	},

	pageTreeBody: {
		id: 'external-collab-ui.guest-request-to-upgrade-page-tree.body',
		defaultMessage: 'You are a guest on this site with limited access.',
		description:
			'This text appears next to the action to request an upgrade, explaining that the user is a guest with limited capabilities',
	},
	requestToUpgradePageTreeButton: {
		id: 'external-collab-ui.guest-request-to-upgrade-page-tree.request-to-upgrade-button',
		defaultMessage: 'Request full access',
		description:
			'After a guest clicks on the button, a request is submitted to upgrade the guest to a full user',
	},
	pendingPageTreeBody: {
		id: 'external-collab-ui.guest-request-to-upgrade-page-tree.pending-body',
		defaultMessage: 'You are a guest on this site.',
		description:
			'This text appears next to the disabled button, explaining that the upgrade request is pending',
	},
	requestToUpgradePageTreePendingButton: {
		id: 'external-collab-ui.guest-request-to-upgrade-page-tree.request-to-upgrade-pending-button',
		defaultMessage: 'Request pending approval',
		description:
			'This text will appear on a disabled button, indicating that the upgrade request is pending',
	},

	flagSuccessTitle: {
		id: 'external-collab-ui.guest-request-to-upgrade.flag-success-title',
		defaultMessage: 'Licensed user request sent!',
		description:
			'Flag notification title informing the guest that their request to be upgraded to a licensed user was successfully submitted',
	},
	flagSuccessDescription: {
		id: 'external-collab-ui.guest-request-to-upgrade.flag-success-description',
		defaultMessage: 'If approved, you’ll get a confirmation message from admin',
		description: 'Flag notification description informing guest that request was submitted',
	},

	flagErrorTitle: {
		id: 'external-collab-ui.guest-request-to-upgrade.flag-error-title',
		defaultMessage: 'Request not sent',
		description: 'Flag notification title informing guest that request encountered an error',
	},
	flagErrorDescription: {
		id: 'external-collab-ui.guest-request-to-upgrade.flag-error-description',
		defaultMessage: 'Refresh the page to resubmit your request, or try again later.',
		description: 'Flag notification description informing guest that request encountered an error',
	},
});

const SECTION_LABEL_ARIA_ID = '__guest-request-to-upgrade-to-user-aria-label-id';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Section = styled.section({
	boxSizing: 'border-box',
	maxWidth: '344px',
	padding: token('space.200', '16px'),
	margin: token('space.100', '8px'),
	backgroundColor: token('color.background.accent.gray.subtlest', '#F1F2F4'),
	borderRadius: token('border.radius.050', '3px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Heading = styled.h2({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	...h400Compiled,
	marginBottom: token('space.100', '8px'),
});

const buttonGroupContainerStyles = xcss({
	paddingTop: 'space.200',
});
export interface GuestRequestToUpgradeSpacesMenuProps extends WithFlagsProps {
	onPermissionDenied: () => void;
	onPermissionError: () => void;
}

const GuestRequestToUpgradeSpacesMenuComponent: FC<GuestRequestToUpgradeSpacesMenuProps> = ({
	flags,
	onPermissionDenied,
	onPermissionError,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showModal } = useDialogs();

	const experienceTracker = useContext(ExperienceTrackerContext);

	const showErrorFlag = useCallback(
		(submissionError: Error) => {
			void flags.showErrorFlag({
				title: <FormattedMessage {...i18n.flagErrorTitle} />,
				description: <FormattedMessage {...i18n.flagErrorDescription} />,
			});
			experienceTracker.fail({
				name: SUBMIT_GUEST_SPACES_MENU_REQUEST_TO_UPGRADE_EXPERIENCE,
				error: submissionError,
			});
		},
		[flags, experienceTracker],
	);

	const onGuestUpgradeRequestSubmitted = useCallback(() => {
		void flags.showSuccessFlag({
			title: <FormattedMessage {...i18n.flagSuccessTitle} />,
			description: <FormattedMessage {...i18n.flagSuccessDescription} />,
		});
		experienceTracker.succeed({
			name: SUBMIT_GUEST_SPACES_MENU_REQUEST_TO_UPGRADE_EXPERIENCE,
		});
	}, [flags, experienceTracker]);

	const handleOnSubmit = (error?: Error) => {
		createAnalyticsEvent({
			type: TRACK_EVENT,
			data: {
				action: !!error ? 'failed' : 'requested',
				actionSubject: 'productAccess',
				source: ANALYTICS_SOURCE,
				attributes: {
					touchpointId: ANALYTICS_SOURCE,
				},
			},
		}).fire();

		if (!error) {
			onGuestUpgradeRequestSubmitted();
		} else {
			showErrorFlag(error);
		}
	};

	const [
		submitGuestUpgradeRequest,
		{ loading: guestUpgradeRequestIsLoading, isSubmitted: guestUpgradeSubmitted },
	] = useSubmitGuestUpgradeRequest({ onCompleted: handleOnSubmit });

	const {
		upgradeStatus,
		upgradeStatusReason,
		loading: permissionLoading,
		error: permissionsError,
	} = useGuestRequestToUpgradeStatus();

	const permissionDenied = upgradeStatus === UpgradeStatus.NOT_ELIGIBLE_TO_UPGRADE_AT_ALL;

	useEffect(() => {
		if (!permissionLoading) {
			if (permissionsError && !isUnauthorizedError(permissionsError)) {
				onPermissionError();
				experienceTracker.stopOnError({
					name: VIEW_GUEST_SPACES_MENU_REQUEST_TO_UPGRADE_EXPERIENCE,
					error: permissionsError,
				});
			} else if (permissionDenied) {
				onPermissionDenied();
				experienceTracker.abort({
					name: VIEW_GUEST_SPACES_MENU_REQUEST_TO_UPGRADE_EXPERIENCE,
					reason: upgradeStatusReason ?? UpgradeStatus.NOT_ELIGIBLE_TO_UPGRADE_AT_ALL,
				});
			}
		}
	}, [
		onPermissionDenied,
		onPermissionError,
		permissionDenied,
		permissionLoading,
		permissionsError,
		experienceTracker,
		upgradeStatusReason,
	]);

	const requestPending =
		guestUpgradeSubmitted || upgradeStatus === UpgradeStatus.AN_UPGRADE_REQUEST_IS_PENDING;

	const submitGuestUpgradeRequestWithAnalytics = (
		_: React.MouseEvent<HTMLElement, MouseEvent>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const analyticsAttributes = {
			...analyticsEvent.payload.attributes,
			touchpointId: ANALYTICS_SOURCE,
		};
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				...analyticsEvent.payload,
				actionSubjectId: 'submitGuestUpgradeRequest',
				source: ANALYTICS_SOURCE,
				attributes: analyticsAttributes,
			},
		}).fire();
		experienceTracker.start({
			name: SUBMIT_GUEST_SPACES_MENU_REQUEST_TO_UPGRADE_EXPERIENCE,
		});
		void submitGuestUpgradeRequest();
	};

	const openLearnMoreModal = (
		_: React.MouseEvent<HTMLElement, MouseEvent>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				...analyticsEvent.payload,
				actionSubjectId: 'guestSpacesMenuUpgradeRequestLearnMore',
				source: ANALYTICS_SOURCE,
				attributes: {
					requested: requestPending,
				},
			},
		}).fire();
		showModal(GuestRequestToUpgradeLearnMoreDialogModal, {
			onSubmit: handleOnSubmit,
			requestPending,
			analytics: {
				touchpointId: ANALYTICS_SOURCE,
			},
			isSpacesMenu: true,
		});
	};

	if (permissionLoading || permissionDenied) {
		return null;
	}

	// https://data-portal.internal.atlassian.com/analytics/registry/68335
	createAnalyticsEvent({
		type: 'sendUIEvent',
		data: {
			action: 'shown',
			actionSubject: 'button',
			actionSubjectId: 'submitGuestUpgradeRequest',
			source: ANALYTICS_SOURCE,
			attributes: {
				touchpointId: ANALYTICS_SOURCE,
				isPending: requestPending,
			},
		},
	}).fire();

	return (
		<Section aria-labelledby={SECTION_LABEL_ARIA_ID}>
			<ExperienceSuccess name={VIEW_GUEST_SPACES_MENU_REQUEST_TO_UPGRADE_EXPERIENCE} />
			<Heading id={SECTION_LABEL_ARIA_ID}>
				<FormattedMessage {...i18n.heading} />
			</Heading>
			<p>
				<FormattedMessage
					{...i18n.body}
					values={{
						learnmorelink: (
							<Button appearance="link" onClick={openLearnMoreModal} spacing="none">
								<FormattedMessage {...i18n.learnMoreButton} />
							</Button>
						),
					}}
				/>
			</p>
			<Box xcss={buttonGroupContainerStyles}>
				<ButtonGroup>
					<GuestRequestUpgradeButton
						appearance="primary"
						requestPending={requestPending}
						loading={guestUpgradeRequestIsLoading}
						onClick={submitGuestUpgradeRequestWithAnalytics}
					/>
				</ButtonGroup>
			</Box>
		</Section>
	);
};

const GuestRequestToUpgradeSpacesMenuWithExperienceTracking: FC<
	GuestRequestToUpgradeSpacesMenuProps
> = (props) => (
	<>
		<ExperienceStart name={VIEW_GUEST_SPACES_MENU_REQUEST_TO_UPGRADE_EXPERIENCE} timeout={3000} />
		<GuestRequestToUpgradeSpacesMenuComponent {...props} />
	</>
);

export const GuestRequestToUpgradeSpacesMenu = withFlags(
	GuestRequestToUpgradeSpacesMenuWithExperienceTracking,
);
