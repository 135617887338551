import { createStore, createHook } from 'react-sweet-state';
import React, { createContext, useRef, useState } from 'react';

import { PermissionOptions } from './PermissionOptions';
import { useBeforeUnload } from './useBeforeUnload';
import { ContentType } from './ContentType';

export const Store = createStore({
	initialState: {
		showImportContentDrawer: false,
		shouldRenderDrawer: false,
		showImportContentModal: false,
		shouldRenderModal: false,
		contentType: ContentType.NOTION,
	},
	actions: {
		setShowImportContentDrawer:
			(value: boolean) =>
			({ setState }) => {
				setState({
					showImportContentDrawer: value,
				});
			},
		setShouldRenderDrawer:
			(value: boolean) =>
			({ setState }) => {
				setState({
					shouldRenderDrawer: value,
				});
			},
		setShowImportContentModal:
			(value: boolean) =>
			({ setState }) => {
				setState({
					showImportContentModal: value,
				});
			},
		setShouldRenderModal:
			(value: boolean) =>
			({ setState }) => {
				setState({
					shouldRenderModal: value,
				});
			},
		setContentType:
			(value: ContentType) =>
			({ setState }) => {
				setState({
					contentType: value,
				});
			},
	},
	name: 'importContentStore',
});

export interface ImportContentWorkflowContextData {
	authProviderError: any;
	setAuthProviderError: (value: any) => void;
	hasSubpages: boolean;
	setHasSubpages: (value: boolean) => void;
	dropzoneCancelFnRef: React.MutableRefObject<Function | null>;
	browserCancelFnRef: React.MutableRefObject<Function | null>;
	isUploadingFile: boolean;
	setIsUploadingFile: (value: boolean) => void;
	isStartingImport: boolean;
	setIsStartingImport: (value: boolean) => void;
	fileNameDisplay: string;
	setFileNameDisplay: (value: string) => void;
	fileSizeBytes: number;
	setFileSizeBytes: (value: number) => void;
	filestoreId: string;
	setFilestoreId: (value: string) => void;
	shouldMapNotionMembers: boolean;
	setShouldMapNotionMembers: (value: boolean) => void;
	shouldRenderIntegrationTokenModal: boolean;
	setShouldRenderIntegrationTokenModal: (value: boolean) => void;
	shouldRenderCloseDrawerModal: boolean;
	setShouldRenderCloseDrawerModal: (value: boolean) => void;
	integrationToken: string;
	setIntegrationToken: (value: string) => void;
	shouldAddRemainingMembers: boolean;
	setShouldAddRemainingMembers: (value: boolean) => void;
	shouldCopyPagePermissions: boolean;
	setShouldCopyPagePermissions: (value: boolean) => void;
	shouldRenderAccessTokenModal: boolean;
	setShouldRenderAccessTokenModal: (value: boolean) => void;
	accessToken: string;
	setAccessToken: (value: string) => void;
	spaceName: string;
	setSpaceName: (value: string) => void;
	selectedPermissions: PermissionOptions;
	setSelectedPermissions: (value: PermissionOptions) => void;
}

export const ImportContentWorkflowContext = createContext<ImportContentWorkflowContextData>({
	authProviderError: '',
	setAuthProviderError: () => {},
	hasSubpages: false,
	setHasSubpages: () => {},
	dropzoneCancelFnRef: { current: null },
	browserCancelFnRef: { current: null },
	isUploadingFile: false,
	setIsUploadingFile: () => {},
	isStartingImport: false,
	setIsStartingImport: () => {},
	fileNameDisplay: '',
	setFileNameDisplay: () => {},
	fileSizeBytes: 0,
	setFileSizeBytes: () => {},
	filestoreId: '',
	setFilestoreId: () => {},
	shouldMapNotionMembers: true,
	setShouldMapNotionMembers: () => {},
	shouldRenderCloseDrawerModal: false,
	setShouldRenderCloseDrawerModal: () => {},
	shouldRenderIntegrationTokenModal: false,
	setShouldRenderIntegrationTokenModal: () => {},
	integrationToken: '',
	setIntegrationToken: () => {},
	shouldAddRemainingMembers: false,
	setShouldAddRemainingMembers: () => {},
	shouldCopyPagePermissions: false,
	setShouldCopyPagePermissions: () => {},
	shouldRenderAccessTokenModal: false,
	setShouldRenderAccessTokenModal: () => {},
	accessToken: '',
	setAccessToken: () => {},
	spaceName: '',
	setSpaceName: () => {},
	selectedPermissions: PermissionOptions.DEFAULT,
	setSelectedPermissions: () => {},
});

export const ImportContentWorkflowContextProvider = (props: any) => {
	const [authProviderError, setAuthProviderError] = useState('');
	const [hasSubpages, setHasSubpages] = useState(false);
	const dropzoneCancelFnRef = useRef<Function | null>(null);
	const browserCancelFnRef = useRef<Function | null>(null);
	const [isUploadingFile, setIsUploadingFile] = useState(false);
	const [isStartingImport, setIsStartingImport] = useState(false);
	const [fileNameDisplay, setFileNameDisplay] = useState('');
	const [fileSizeBytes, setFileSizeBytes] = useState(0);
	const [filestoreId, setFilestoreId] = useState('');
	const [shouldMapNotionMembers, setShouldMapNotionMembers] = useState(true);
	const [shouldRenderIntegrationTokenModal, setShouldRenderIntegrationTokenModal] = useState(false);
	const [shouldRenderCloseDrawerModal, setShouldRenderCloseDrawerModal] = useState(false);
	const [integrationToken, setIntegrationToken] = useState('');
	const [shouldAddRemainingMembers, setShouldAddRemainingMembers] = useState(true);
	const [shouldCopyPagePermissions, setShouldCopyPagePermissions] = useState(false);
	const [shouldRenderAccessTokenModal, setShouldRenderAccessTokenModal] = useState(false);
	const [accessToken, setAccessToken] = useState('');
	const [spaceName, setSpaceName] = useState('');
	const [selectedPermissions, setSelectedPermissions] = useState(PermissionOptions.DEFAULT);

	useBeforeUnload('Changes may not be saved', isUploadingFile || isStartingImport);

	return (
		<ImportContentWorkflowContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				authProviderError,
				setAuthProviderError,
				hasSubpages,
				setHasSubpages,
				dropzoneCancelFnRef,
				browserCancelFnRef,
				isUploadingFile,
				setIsUploadingFile,
				isStartingImport,
				setIsStartingImport,
				fileNameDisplay,
				setFileNameDisplay,
				fileSizeBytes,
				setFileSizeBytes,
				filestoreId,
				setFilestoreId,
				shouldMapNotionMembers,
				setShouldMapNotionMembers,
				shouldRenderCloseDrawerModal,
				setShouldRenderCloseDrawerModal,
				shouldRenderIntegrationTokenModal,
				setShouldRenderIntegrationTokenModal,
				integrationToken,
				setIntegrationToken,
				shouldAddRemainingMembers,
				setShouldAddRemainingMembers,
				shouldCopyPagePermissions,
				setShouldCopyPagePermissions,
				shouldRenderAccessTokenModal,
				setShouldRenderAccessTokenModal,
				accessToken,
				setAccessToken,
				spaceName,
				setSpaceName,
				selectedPermissions,
				setSelectedPermissions,
			}}
		>
			{props.children}
		</ImportContentWorkflowContext.Provider>
	);
};

export const useImportContentStore = createHook(Store);
