import React from 'react';
import PropTypes from 'prop-types';
import idx from 'idx';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N800 } from '@atlaskit/theme/colors';

import { AccessStatus, useSessionData } from '@confluence/session-data';
import { SpaceIcon } from '@confluence/icons/entry-points/SpaceIcon';
import { SpaceStarButton } from '@confluence/action-buttons/entry-points/spaceStar';

import { ListItemLayout } from '../layouts/ListItemLayout';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TitleText = styled.div({
	whiteSpace: 'nowrap',
	overflowX: 'hidden',
	textOverflow: 'ellipsis',
	color: token('color.text', N800),
	top: '1px',
});

export const SpaceItemView = ({
	space,
	analytics,
	spaceIconSize = 'medium',
	buttonSize = 'medium',
	tooltipPosition = 'bottom',
	noStar = false,
}) => {
	const { accessStatus } = useSessionData();
	const allowStarring =
		accessStatus === AccessStatus.LICENSED_USE_ACCESS ||
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.EXTERNAL_COLLABORATOR_ACCESS;

	const { name, type } = space;
	const iconPath = idx(space, (_) => _.icon.path);

	const primary = <TitleText id={`space-link-${space.key}`}>{name}</TitleText>;
	const icon = <SpaceIcon type={type} size={spaceIconSize} src={iconPath} label="" />;
	if (analytics) {
		analytics.attributes = {
			...analytics.attributes,
			isGuest: accessStatus === AccessStatus.EXTERNAL_COLLABORATOR_ACCESS,
		};
	}

	const star = allowStarring && (
		<SpaceStarButton
			spaceId={space.id}
			spaceKey={space.key}
			isStarred={idx(space, (_) => _.currentUser.isFavourited)}
			analytics={analytics}
			size={buttonSize}
			tooltipPosition={tooltipPosition}
			legacy
		/>
	);

	return <ListItemLayout icon={icon} centerIcon primary={primary} actions={!noStar && star} />;
};

SpaceItemView.propTypes = {
	/** Space type from GraphQL */
	space: PropTypes.object.isRequired,
	analytics: PropTypes.object,
	spaceIconSize: PropTypes.string,
	buttonSize: PropTypes.string,
	tooltipPosition: PropTypes.string,
	noStar: PropTypes.bool,
};
