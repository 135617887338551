export const CapabilityValues = {
	ACCESS_EXISTS: 'ACCESS_EXISTS',
	DIRECT_ACCESS: 'DIRECT_ACCESS',
	APPROVED_REQUEST_EXISTS: 'APPROVED_REQUEST_EXISTS',
	DENIED_REQUEST_EXISTS: 'DENIED_REQUEST_EXISTS',
	FORBIDDEN: 'FORBIDDEN',
	PENDING_REQUEST_EXISTS: 'PENDING_REQUEST_EXISTS',
	REQUEST_ACCESS: 'REQUEST_ACCESS',
};

export enum UpgradeStatus {
	CAN_REQUEST = 'CAN_REQUEST',
	AN_UPGRADE_REQUEST_IS_PENDING = 'AN_UPGRADE_REQUEST_IS_PENDING',
	NOT_ELIGIBLE_TO_UPGRADE_AT_ALL = 'NOT_ELIGIBLE_TO_UPGRADE_AT_ALL',
}

export type UpgradeStatusReason = keyof typeof CapabilityValues;
