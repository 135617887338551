import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import { Box } from '@atlaskit/primitives';
import SearchIcon from '@atlaskit/icon/core/search';
import CloseIcon from '@atlaskit/icon/core/close';
import Textfield from '@atlaskit/textfield';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireSpacesMenuItemClickedAnalytics,
);

type SpacesDropdownTextFilterProps = {
	filterText: string;
	setFilterText: (filterText: string) => void;
	placeholder: string;
};

export const SpacesDropdownTextFilter = ({
	filterText,
	setFilterText,
	placeholder,
}: SpacesDropdownTextFilterProps) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireTextFilterClickedAnalytics = useLazyClickAnalytics(createAnalyticsEvent, '4', {
		componentType: 'fixedItem',
		itemId: 'filterSpaces',
	});

	return (
		<Textfield
			value={filterText}
			onChange={(e) => setFilterText(e.currentTarget.value)}
			onMouseDown={fireTextFilterClickedAnalytics}
			elemBeforeInput={
				<Box paddingInline="space.100">
					<SearchIcon label="" color="currentColor" />
				</Box>
			}
			elemAfterInput={
				Boolean(filterText) && (
					<Box paddingInlineEnd="space.050">
						<IconButton
							appearance="subtle"
							spacing="compact"
							icon={CloseIcon}
							label={intl.formatMessage(i18n.clearFilter)}
							onClick={() => setFilterText('')}
						/>
					</Box>
				)
			}
			isCompact
			placeholder={placeholder}
		/>
	);
};

const i18n = defineMessages({
	clearFilter: {
		id: 'spaces-dropdown.text-filter.clear.filter',
		defaultMessage: 'Clear filter',
		description: 'Text for button to clear the filter in the spaces dropdown',
	},
});
