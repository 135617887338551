import { LoadableLazy } from '@confluence/loadable';

export const SpacesDropdown = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-global-spaces-dropdown" */ './SpacesDropdown'))
			.SpacesDropdown,
});

export const SpacesDropdownError = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SpacesDropdownError" */ './SpacesDropdownError'))
			.SpacesDropdownError,
});

export { SpacesDropdown as SpacesDropdownMenu } from './SpacesDropdown';
export { EmptyContainer, LoadingContainer } from './SpacesDropdownContent';
export { HoverableItemViewContainer } from './SpacesDropdownItem';
export { SpaceList } from './SpacesDropdownSection';
