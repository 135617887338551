import { useEffect } from 'react';
/**
 * Custom hook to detect when the user is attempting to close the browser tab.
 * Note: Modern browsers might not display a custom message in the dialog.
 *
 * I have tested chrome - mac (en-US and japan languages) and the custom message does NOT display, it uses the default message
 * @param message - The message you might want to show in the dialog.
 */
export const useBeforeUnload = (message: string, isUploadingFile: boolean): void => {
	useEffect(() => {
		if (isUploadingFile) {
			const handleBeforeUnload = (event: BeforeUnloadEvent) => {
				event.preventDefault(); // Required to trigger the dialog in some browsers

				event.returnValue = message;
			};
			window.addEventListener('beforeunload', handleBeforeUnload);
			return () => {
				window.removeEventListener('beforeunload', handleBeforeUnload);
			};
		}
	}, [message, isUploadingFile]);
};
