import React from 'react';
import type { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { ButtonItem } from '@atlaskit/menu';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { MenuButtonItem } from '@atlassian/navigation-system/side-nav/menu-button-item';

import { useImportContentStore } from '../ImportContentStore';

const i18n = defineMessages({
	buttonText: {
		id: 'import-content.button.text',
		defaultMessage: 'Import from other tools',
		description:
			'Text on the button that opens a user experience to import content into Confluence',
	},
});

type ImportContentButtonProps = {
	isNav4Enabled?: boolean;
	closePopup?: (reason?: string | undefined) => void;
};

export const ImportContentButton: FC<ImportContentButtonProps> = ({
	isNav4Enabled,
	closePopup,
}) => {
	const [, importContentActions] = useImportContentStore();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const ButtonComponent = isNav4Enabled ? MenuButtonItem : ButtonItem;

	return (
		<ButtonComponent
			onClick={() => {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'button',
						source: 'importContentButton',
					},
				}).fire();
				importContentActions.setShouldRenderModal(true);
				importContentActions.setShowImportContentModal(true);
				closePopup?.('import content clicked');
			}}
		>
			<FormattedMessage {...i18n.buttonText} />
		</ButtonComponent>
	);
};
