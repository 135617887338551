import { useCallback, useEffect, useState } from 'react';

import { useSessionData } from '@confluence/session-data';
import { cfetch } from '@confluence/network';

import { CapabilityValues, UpgradeStatus, type UpgradeStatusReason } from './types';

export const DO_DISPLAY_VALUES = [
	// If a guest has "direct access", they will simply upgrade to a user immediately upon submitting a request without needing admin approval.
	CapabilityValues.DIRECT_ACCESS,
	// ... otherwise, they have the ability to request access, and wait for admin approval.
	CapabilityValues.REQUEST_ACCESS,
];

export const DO_NOT_DISPLAY_VALUES = [
	// The ones a Guest should never get, but technically exist on the endpoint:
	CapabilityValues.ACCESS_EXISTS,
	CapabilityValues.APPROVED_REQUEST_EXISTS,
	// The ones we expect to potentially get:
	CapabilityValues.DENIED_REQUEST_EXISTS,
	CapabilityValues.FORBIDDEN,
];

export const ROLE_GUEST_IS_UPGRADING_TO = 'product/member';

export const useGuestRequestToUpgradeStatus: (skip?: boolean) => {
	upgradeStatus: UpgradeStatus | undefined;
	upgradeStatusReason?: UpgradeStatusReason;
	loading: boolean;
	error?: Error;
} = (skip = false) => {
	// if skip is true, we are not going to fetch the status, so we're not loading anything and it can be false
	// if skip is false, we are going to fetch the status and invoke API immediately, so loading can be true (same as previous implementation)
	const [loading, setLoading] = useState<boolean>(!skip);
	const [error, setError] = useState<Error>();
	const [upgradeStatus, setUpgradeStatus] = useState<UpgradeStatus | undefined>(undefined);
	const [upgradeStatusReason, setUpgradeStatusReason] = useState<UpgradeStatusReason>();

	const { cloudId } = useSessionData();

	const fetchGuestUpgradeRequestStatus = useCallback(async () => {
		setLoading(true);
		const resource = `ari:cloud:confluence::site/${cloudId}`;
		const params = new URLSearchParams({
			resource,
		});
		return cfetch(`/gateway/api/invitations/v1/access-requests/capabilities?${params.toString()}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
			},
		})
			.then((response) => response.json())
			.then((data) => {
				const statusArray = data.resultsV2[resource];
				const userCapabilityEntry =
					statusArray.find((entry) => entry.role === ROLE_GUEST_IS_UPGRADING_TO) || {};
				const status = userCapabilityEntry.accessMode;
				setUpgradeStatusReason(status);

				if (DO_NOT_DISPLAY_VALUES.includes(status)) {
					setUpgradeStatus(UpgradeStatus.NOT_ELIGIBLE_TO_UPGRADE_AT_ALL);
				} else if (status === CapabilityValues.PENDING_REQUEST_EXISTS) {
					setUpgradeStatus(UpgradeStatus.AN_UPGRADE_REQUEST_IS_PENDING);
				} else if (DO_DISPLAY_VALUES.includes(status)) {
					setUpgradeStatus(UpgradeStatus.CAN_REQUEST);
				} else {
					// We didn't get a valid value
					throw Error(`Did not receive a valid value for the accessMode status: ${status}.`);
				}
			})
			.catch((caughtError) => {
				// If anything fails, we just state you're not eligible, because we can't confirm it.
				setUpgradeStatus(UpgradeStatus.NOT_ELIGIBLE_TO_UPGRADE_AT_ALL);
				setError(
					caughtError instanceof Error
						? caughtError
						: new Error('Guest request to upgrade status load error', {
								cause: caughtError,
							}),
				);
			})
			.finally(() => setLoading(false));
	}, [cloudId]);

	useEffect(() => {
		if (skip) {
			return;
		}
		void fetchGuestUpgradeRequestStatus();
	}, [fetchGuestUpgradeRequestStatus, skip]);

	return {
		upgradeStatus,
		upgradeStatusReason,
		loading,
		error,
	};
};
