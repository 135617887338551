import type { FC } from 'react';
import React from 'react';

import { LoadableLazy } from '@confluence/loadable';

import { useImportContentStore } from '../ImportContentStore';

const ImportContentDrawerLoader = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ImportContentDrawer" */ './ImportContentDrawer'))
			.ImportContentDrawer,
});

export const ConditionalImportContentDrawer: FC = () => {
	const [importContentState, importContentActions] = useImportContentStore();
	return importContentState.shouldRenderDrawer ? (
		<ImportContentDrawerLoader
			isImportingContent={importContentState.showImportContentDrawer}
			setIsImportingContent={importContentActions.setShowImportContentDrawer}
			contentType={importContentState.contentType}
		/>
	) : null;
};
