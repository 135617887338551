import type { FC } from 'react';
import React from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { LoadableLazy } from '@confluence/loadable';

import { useImportContentStore } from '../ImportContentStore';
import { ContentType } from '../ContentType';

const ImportContentModalLoader = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ImportContentModal" */ './ImportContentModal'))
			.ImportContentModal,
});

const actionSubjectMap = {
	[ContentType.NOTION]: 'notionCard',
	[ContentType.MIRO]: 'miroCard',
	[ContentType.HTML]: 'fileImportCard',
};

export const ConditionalImportContentModal: FC = () => {
	const [importContentState, importContentActions] = useImportContentStore();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const makeCardClickHandler = (contentType: ContentType) => () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: actionSubjectMap[contentType],
				source: 'importContentModal',
			},
		}).fire();
		importContentActions.setShowImportContentModal(false);
		importContentActions.setContentType(contentType);
		importContentActions.setShouldRenderDrawer(true);
		importContentActions.setShowImportContentDrawer(true);
	};

	return importContentState.shouldRenderModal ? (
		<ImportContentModalLoader
			shouldRender={importContentState.showImportContentModal}
			onClose={() => importContentActions.setShowImportContentModal(false)}
			onMiroClick={makeCardClickHandler(ContentType.MIRO)}
			onNotionClick={makeCardClickHandler(ContentType.NOTION)}
			onFileImportClick={makeCardClickHandler(ContentType.HTML)}
		/>
	) : null;
};
