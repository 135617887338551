import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N200, N70, B300 } from '@atlaskit/theme/colors';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ListItemContainer = styled.div({
	display: 'flex',
	padding: 0,
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ListItemTopDefault = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	alignItems: (props) => (props.centerIcon ? 'center' : 'unset'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ListItemTopBreadcrumbs = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	alignItems: (props) => (props.centerIcon ? 'initial' : 'unset'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div > span > svg': {
		paddingTop: token('space.050', '4px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ListItemContent = styled.div({
	flexGrow: 1,
	overflow: 'hidden',
	overflowWrap: 'break-word',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: (props) => (props.isAdvancedSearchResult ? '3px 0 0 34px' : '4px 0 0 34px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ListItemIcon = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...(props) =>
		props.isAdvancedSearchResult && {
			'> div > span': {
				height: '24px',
				width: '24px',

				'> svg': {
					height: '20px',
					width: '20px',
					padding: `${token('space.050', '4px')} ${token(
						'space.025',
						'2px',
					)} 0px ${token('space.025', '2px')}`,
				},
			},
		},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ListItemPrimarySecondary = styled.div({
	padding: '0 0 0 10px',
	overflow: 'hidden',
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ListItemPrimary = styled.div({
	display: 'flex',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	paddingBottom: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ListItemSecondaryDefault = styled.div({
	color: token('color.text.subtle', N200),
	margin: 0,
	fontSize: '11px',
	lineHeight: '14px',
	display: 'flex',
	alignItems: 'baseline',
	whiteSpace: 'nowrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> a': {
		color: token('color.link', N70),
		'&:visited': {
			color: token('color.link', N70),
		},
		'&:hover': {
			color: token('color.link.pressed', B300),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ListItemSecondaryBreadcrumbs = styled.div({
	color: token('color.text.subtle', N200),
	margin: '1px 0 0 0',
	fontSize: '11px',
	lineHeight: '14px',
	display: 'flex',
	alignItems: 'baseline',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> a': {
		color: token('color.link', N70),
		'&:visited': {
			color: token('color.link', N70),
		},
		'&:hover': {
			color: token('color.link.pressed', B300),
		},
	},
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ListItemActions = styled.div({
	marginLeft: token('space.050', '4px'),
	flexShrink: 0,
});

export const ListItemLayout = ({
	icon,
	centerIcon,
	primary,
	secondary,
	content,
	actions,
	editButton,
	className,
	isBreadcrumbEnabled = false,
	isAdvancedSearchResult = false,
}) => {
	const ListItemTop = isBreadcrumbEnabled ? ListItemTopBreadcrumbs : ListItemTopDefault;
	const ListItemSecondary = isBreadcrumbEnabled
		? ListItemSecondaryBreadcrumbs
		: ListItemSecondaryDefault;
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<ListItemContainer className={className}>
			<ListItemTop centerIcon={centerIcon}>
				<ListItemIcon isAdvancedSearchResult={isAdvancedSearchResult} data-testid="list-item-icon">
					{icon}
				</ListItemIcon>
				<ListItemPrimarySecondary data-csstargetingattr="ListItemPrimarySecondary">
					<ListItemPrimary>{primary}</ListItemPrimary>
					<ListItemSecondary>{secondary}</ListItemSecondary>
				</ListItemPrimarySecondary>
				{editButton && (
					<ListItemActions data-csstargetingattr="ListItemEdit">{editButton}</ListItemActions>
				)}
				{actions && (
					<ListItemActions data-csstargetingattr="ListItemActions">{actions}</ListItemActions>
				)}
			</ListItemTop>
			{content && (
				<ListItemContent
					isAdvancedSearchResult={isAdvancedSearchResult}
					data-testid="list-item-content"
				>
					{content}
				</ListItemContent>
			)}
		</ListItemContainer>
	);
};

ListItemLayout.propTypes = {
	primary: PropTypes.node.isRequired,
	centerIcon: PropTypes.bool,
	icon: PropTypes.node,
	secondary: PropTypes.node,
	content: PropTypes.node,
	editButton: PropTypes.node,
	actions: PropTypes.node,
	className: PropTypes.string,
	isBreadcrumbEnabled: PropTypes.bool,
	isAdvancedSearchResult: PropTypes.bool,
};
