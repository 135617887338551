import React, { type ReactNode } from 'react';

import { useId } from '@atlaskit/ds-lib/use-id';

import { MenuSectionContext } from './menu-section-context';

type MenuSectionProps = {
	children?: ReactNode;
};

/**
 * __MenuSection__
 *
 * A composable component for grouping menu items, along with a heading (`MenuSectionHeading`) and a divider (`Divider`).
 *
 * Usage example:
 * ```jsx
 * <MenuSection>
 *   <MenuSectionHeading>Section heading</MenuSectionHeading>
 *   <MenuList>
 *     <MenuItem>Item 1</MenuItem>
 *     <MenuItem>Item 2</MenuItem>
 *   </MenuList>
 *   <Divider />
 * </MenuSection>
 * ```
 */
export const MenuSection = ({ children }: MenuSectionProps): JSX.Element => {
	const id = useId();

	return (
		<MenuSectionContext.Provider value={id}>
			<div role="group" aria-labelledby={`${id}-heading`}>
				{children}
			</div>
		</MenuSectionContext.Provider>
	);
};
