/** @jsx jsx */
/** @jsxFrag */
import type { FC } from 'react';
import { useContext, useCallback, useState, Fragment } from 'react';
import { cssMap, jsx } from '@compiled/react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { Subscribe } from 'unstated';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Section, LinkItem } from '@atlaskit/menu';
import AlignLeftIcon from '@atlaskit/icon/core/align-left';
import { Box, xcss } from '@atlaskit/primitives';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';
import { MenuSection, Divider } from '@atlassian/navigation-system/side-nav/menu-section';

import { BannerStateContainer } from '@confluence/banners';
import { Attribution, GenericErrorBoundary } from '@confluence/error-boundary';
import { SPACE_DIRECTORY } from '@confluence/named-routes';
import {
	CreateSpaceButton,
	useCanCreateSpace,
} from '@confluence/create-space/entry-points/createSpace';
import { ImportContentButton } from '@confluence/import-content/entry-points/importContent';
import { AppNavigationContext } from '@confluence/app-navigation-context';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';
import { useFlyoutStore } from '@confluence/side-navigation/entry-points/flyoutStore';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';

import { SpacesDropdownContent } from './SpacesDropdownContent';
import { SpacesDropdownError } from './SpacesDropdownError';
import { SpacesDropdownTextFilter } from './SpacesDropdownTextFilter';

const nav3Styles = cssMap({
	unstyledLinkItem: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&&': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'&:hover, &:active, &:visited, &:focus': {
				textDecoration: 'none',
			},
		},
	},
});

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireSpacesMenuItemClickedAnalytics,
);

type SpacesDropdownProps = {
	isNav4Enabled: boolean;
	closePopup?: (reason?: string | undefined) => void;
};

export const SpacesDropdown: FC<SpacesDropdownProps> = ({ isNav4Enabled, closePopup }) => {
	const intl = useIntl();
	const { resetStickySearchRef, primaryDropdownItemRef } = useContext(AppNavigationContext);
	const { isSiteAdmin } = useContext(SPAViewContext);
	const [, flyoutActions] = useFlyoutStore();
	const [filterText, setFilterText] = useState('');

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireViewAllClickItemAnalytics = useLazyClickAnalytics(
		createAnalyticsEvent,
		isNav4Enabled ? '4' : '3',
		{
			componentType: 'fixedItem',
			itemId: 'viewAllSpacesItems',
		},
	);

	const onViewAllClick = useCallback(() => {
		flyoutActions.closeFlyout();
		closePopup?.('view all clicked');
		resetStickySearchRef?.current?.();
		void fireViewAllClickItemAnalytics();
	}, [closePopup, resetStickySearchRef, flyoutActions, fireViewAllClickItemAnalytics]);

	const fireCreateSpaceItemAnalytics = useLazyClickAnalytics(
		createAnalyticsEvent,
		isNav4Enabled ? '4' : '3',
		{
			componentType: 'fixedItem',
			itemId: 'createAllSpacesItems',
		},
	);

	const onCreateSpaceClick = useCallback(() => {
		closePopup?.('create space clicked');
		void fireCreateSpaceItemAnalytics();
	}, [closePopup, fireCreateSpaceItemAnalytics]);

	const { canCreateSpace } = useCanCreateSpace();

	const isImportContentEnabled = useBooleanFeatureFlag(
		'confluence.frontend.show-import-content-button',
	);

	const isImportAllowedForEndUsers = useBooleanFeatureFlag(
		'confluence.frontend.allow-import-for-end-users',
	);

	const canShowImportContentButton =
		isImportContentEnabled && (isSiteAdmin || isImportAllowedForEndUsers) && canCreateSpace;

	const importContentButton = useCallback(
		() =>
			canShowImportContentButton && (
				<ImportContentButton closePopup={closePopup} isNav4Enabled={isNav4Enabled} />
			),
		[closePopup, isNav4Enabled, canShowImportContentButton],
	);

	const footerSection = isNav4Enabled ? (
		<MenuSection>
			<Divider />
			<MenuLinkItem
				href={SPACE_DIRECTORY.toUrl()}
				onClick={onViewAllClick}
				elemBefore={<AlignLeftIcon label="" color="currentColor" />}
			>
				<FormattedMessage {...i18n.viewAll} />
			</MenuLinkItem>
			<CreateSpaceButton
				source="spacesDropdown"
				appearance="menu-item"
				isTop={false}
				onClick={() => closePopup?.('create space clicked')}
				customFocusRef={primaryDropdownItemRef}
			/>
			{importContentButton()}
		</MenuSection>
	) : (
		<Section hasSeparator>
			<LinkItem
				href={SPACE_DIRECTORY.toUrl()}
				onClick={onViewAllClick}
				css={nav3Styles.unstyledLinkItem}
				UNSAFE_shouldDisableRouterLink
			>
				<FormattedMessage {...i18n.viewAll} />
			</LinkItem>
			<CreateSpaceButton
				source="spacesDropdown"
				appearance="menu-item-nav3"
				isTop={false}
				onClick={onCreateSpaceClick}
				customFocusRef={primaryDropdownItemRef}
			/>
			{importContentButton()}
		</Section>
	);

	return (
		<Subscribe to={[BannerStateContainer]}>
			{(bannerState: BannerStateContainer) => (
				<Box
					xcss={!isNav4Enabled ? nav3DropdownContainerStyles : nav4DropdownContainerStyles}
					testId="spaces-dropdown"
					style={{
						maxHeight: `calc(100vh - ${isNav4Enabled ? '26px' : '79px'} - ${bannerState.getTotalHeight(false)}px)`,
					}}
				>
					{isNav4Enabled && (
						<Fragment>
							<Box xcss={headingStyles}>{intl.formatMessage(i18n.spacesTitle)}</Box>
							<Box paddingBlock="space.075" paddingInlineStart="space.050">
								<SpacesDropdownTextFilter
									filterText={filterText}
									setFilterText={setFilterText}
									placeholder={intl.formatMessage(i18n.filterPlaceholder)}
								/>
							</Box>
						</Fragment>
					)}
					<Box xcss={dropdownScrollAreaStyles}>
						<GenericErrorBoundary
							attribution={Attribution.DISCO}
							renderOnError={SpacesDropdownError}
						>
							<SpacesDropdownContent isNav4Enabled={isNav4Enabled} filterText={filterText} />
						</GenericErrorBoundary>
					</Box>
					{footerSection}
				</Box>
			)}
		</Subscribe>
	);
};

const nav3DropdownContainerStyles = xcss({
	color: 'color.text',
	width: '300px',
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'column',
});

const nav4DropdownContainerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
});

const dropdownScrollAreaStyles = xcss({
	overflow: 'auto',
});

const headingStyles = xcss({
	height: '32px',
	paddingLeft: 'space.075',
	paddingBlock: 'space.150',
	color: 'color.text.subtle',
	fontWeight: '700',
	fontSize: 'font.body',
	display: 'flex',
	alignItems: 'center',
});

const i18n = defineMessages({
	viewAll: {
		id: 'spaces-dropdown.view-all',
		defaultMessage: 'View all spaces',
		description: 'Text for link to space directory',
	},
	filterPlaceholder: {
		id: 'spaces-dropdown.filter-placeholder',
		defaultMessage: 'Filter spaces',
		description: 'Placeholder text for the filter input in the spaces dropdown',
	},
	spacesTitle: {
		id: 'spaces-dropdown.spaces-title',
		defaultMessage: 'Spaces',
		description: 'Title for the spaces dropdown',
	},
});
