/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { css, jsx } from '@compiled/react';

import { token } from '@atlaskit/tokens';

const styles = css({
	width: '100%',
	borderBlockEnd: 'none',
	borderBlockStart: `1px solid ${token('color.border')}`,
	borderInline: 'none',
});

export const Divider = () => <hr css={styles} />;
