import { useEffect, useState } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

export enum GUEST_SPACES_MENU_UPGRADE_FLOW_COHORTS {
	NOT_ENROLLED = 'not-enrolled',
	CONTROL = 'control',
	VARIATION = 'variation',
}

export const GUEST_SPACES_MENU_UPGRADE_FLOW_FLAG = 'guest_spaces_menu_upgrade_flow';

export const GUEST_SPACES_DROPDOWN = 'guestSpaceDropdown';

type GuestSpacesMenuUpgradeFlowExperiment = {
	cohort: GUEST_SPACES_MENU_UPGRADE_FLOW_COHORTS;
	shouldRenderExperiment: boolean;
};

type GuestSpacesMenuUpgradeFlowExperimentParams = {
	meetsEnrollmentCriteria: boolean;
};

export const useGuestSpacesMenuUpgradeFlowABExperiment = ({
	meetsEnrollmentCriteria,
}: GuestSpacesMenuUpgradeFlowExperimentParams): GuestSpacesMenuUpgradeFlowExperiment => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [cohort, setCohort] = useState<GUEST_SPACES_MENU_UPGRADE_FLOW_COHORTS>(
		GUEST_SPACES_MENU_UPGRADE_FLOW_COHORTS.NOT_ENROLLED,
	);

	useEffect(() => {
		if (!meetsEnrollmentCriteria) {
			setCohort(GUEST_SPACES_MENU_UPGRADE_FLOW_COHORTS.NOT_ENROLLED);
			return;
		}

		const [expConfig, fireExperimentExposure] = UNSAFE_noExposureExp(
			'guest_spaces_menu_upgrade_flow',
		);
		const cohort = expConfig.get(
			'cohort',
			'not-enrolled',
		) as GUEST_SPACES_MENU_UPGRADE_FLOW_COHORTS;

		setCohort(cohort);

		if (
			[
				GUEST_SPACES_MENU_UPGRADE_FLOW_COHORTS.VARIATION,
				GUEST_SPACES_MENU_UPGRADE_FLOW_COHORTS.CONTROL,
			].includes(cohort)
		) {
			// Statsig exposure event
			fireExperimentExposure();

			// Regular exposure event for sanity check
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					actionSubject: 'feature',
					action: 'exposed',
					source: GUEST_SPACES_DROPDOWN,
					attributes: {
						flagKey: GUEST_SPACES_MENU_UPGRADE_FLOW_FLAG,
						flagValue: cohort,
						touchpointId: GUEST_SPACES_DROPDOWN,
					},
				},
			}).fire();
		}
	}, [createAnalyticsEvent, meetsEnrollmentCriteria]);

	return {
		cohort,
		shouldRenderExperiment: cohort === GUEST_SPACES_MENU_UPGRADE_FLOW_COHORTS.VARIATION,
	};
};
